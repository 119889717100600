import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import BlogPostCard from "../components/blog/blogpostcard";
import BlogPostWordpressCard from "../components/blog/blogpostcardWordpress";
import Header from '../components/blog/header';
import Subscribe from '../components/common/subscribe';


const BlogPage = ({data, path}) => (
  <Layout pageTitle="Hive Index Community Blog" enableShare={true} hideBlogPosts={true}>
    <SEO title="Community Blog" description="Community guest blog, written by community-minded entrepreneurs, for community-minded entrepreneurs."
      generateImage={true} path={'/blog/'}
    />

    <div className="mx-auto">
      <div id="recent" className="mt-0">
        <div className="relative">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-200" />
          </div>
          <div className="relative flex items-center justify-between">
            <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">Recently published</h2>
          </div>
        </div>
        <div className="">
          <ul className={`mt-4 grid sm:grid-cols-3 gap-x-8 gap-y-4`}>
            {data.recentWp.nodes.map((node, index) => (
              <BlogPostWordpressCard key={node.slug} node={node} mini={false}/>
            ))}
            {data.recent.edges.map((edge, index) => (
              <BlogPostCard key={edge.node.frontmatter.slug} node={edge.node} mini={false}/>
            ))}
          </ul>
        </div>
      </div>

      <div className="mt-12">
        <div className="relative">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-200" />
          </div>
          <div className="relative flex items-center justify-between">
            <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">Subscribe to the good stuff</h2>
          </div>
        </div>
        <div className="mt-4">
          <Subscribe />
        </div>
      </div>
    </div>

  </Layout>
)

export const query = graphql`
query {
  recentWp: allWpPost(
    sort: {fields: date, order: DESC}
  ) {
    nodes {
      id
      date
      slug
      title
      status
      excerpt
      dateGmt
      tags {
        nodes {
          name
          slug
          count
        }
      }
      author {
        node {
          name
          slug
          firstName
          avatar {
            url
          }
        }
      }
    }
  }
  recent: allMdx(
    filter: { frontmatter: { published: {ne: ""}, type: {eq: "post"}}},
    sort: { order: DESC, fields: [frontmatter___published, frontmatter___slug] }
    limit: 12
  ) {
    totalCount
    edges {
      node {
        timeToRead
        excerpt
        frontmatter {
          slug
          published
          author
          authorImage
          title
          description
          imageUrl
        }
      }
    }
  }
}
`

export default BlogPage;
